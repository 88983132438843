<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="blog"
              backText="blogs"
              title="Blog Details"
              sub-title="write down the event"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <v-container>
      <main class="pb-10">
        <div>
          <list class="h-100 wa__large" :class="{ 'loading-form': getDataLoading }">
          <loading v-if="getDataLoading" class="loading-data" />>
            <div class="pb-16 pt-12">
              <div class="blog__detail__image">
                <div>
                  <img
                    :src="blogDetails.thumbnail"
                    class="rounded w-100"
                    alt="blog detail image"
                  />
                </div>
                <div class="name__box mt-5">
                  <div class="blog__detail__name wa__f__m__eb">
                    {{ blogDetails.title }}
                  </div>
                  <div class="blog__detail__schedule">
                    <small class="mr-4"
                      >{{ blogDetails.created_at.split(' ')[0],

                      }}{{ blogDetails.created_at.split(",")[1] }}</small
                    >
                    <span> {{ blogDetails.created_at.split(",")[2] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-10">
                <SectionTitle title="summary" />
                <div class="pr-10 mt-3">
                  {{ blogDetails.summary }}
                </div>
              </div>
              <div class="mt-16 blog__detail--detail">
                <SectionTitle title="text and tags" />
                <div class="pr-10 mt-3" v-html="blogDetails.text"></div>
                <div class="mt-10">
                  <v-chip
                    v-for="(tag, index) in blogDetails.tags"
                    :key="index"
                    text-color="black"
                    color="#e6e6e6d1"
                    label
                    class="chip__tag mr-1"
                    small
                  >
                    {{ tag }}
                  </v-chip>
                </div>
              </div>
              <div v-if="blogDetails.users && blogDetails.users.length" class="mt-16 blog__detail__model__tagged">
                <SectionTitle title="models tagged" />
                <div class="d-flex justify-start items mt-3">
                  <div
                    v-for="user in blogDetails.users"
                    :key="user.id"
                    class="pr-sm-3 mt-2 mt-sm-0 item"
                  >
                    <div>
                      <router-link :to="{ name: 'modelDetailBlog', params: { id: user.id,locale: $_getlocaleParam() } }"  class="text-decoration-none">
                        <div>
                          <picture>
                            <img
                              data-async-image="true"
                              :src="user.model.thumbnail"
                              class="model__image w-100"
                              decoding="async"
                          /></picture>
                        </div>
                        <!--  -->
                        <div class="model__title black--text text-uppercase mt-2">
                          {{ user.name }}
                        </div>
                        <div class="model__sub-title black--text">
                          {{ user.country.name }}
                          {{ user.model.height }} / {{ user.model.weight }} /
                          {{ user.model.bust }} / {{ user.model.waist }} /
                          {{ user.model.hips }}
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </list>
        </div>
      </main>
    </v-container>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import BlogRepository from "@/abstraction/repository/blog/blogRepository";
const blogRepository = new BlogRepository();
export default {
  name: "blog_detail",
  components: {
    SectionTitle,
  },
  data: () => ({
    blogDetails: {
      created_at: "",
      getDataLoading : false,
    },
  }),
  methods: {
    async loadBlogDetails() {
      try {
        this.getDataLoading = true;
        this.blogDetails = await blogRepository.show(this.$route.params.id);
      } catch (error) {
        return error;
      } finally {
        this.getDataLoading = false;
      }
    },
  },
  created() {
    this.loadBlogDetails();
  },
};
</script>
<style scoped>
/* container */
.container {
  max-width: 1640px;
}

.blog__detail__image {
  width: 640px;
  /* height: 360px; */
  position: relative;
  margin: 0 auto;
}
.name__box {
  line-height: 36px;
}
.blog__detail__name {
  font-family: "Montserrat-regular";
  font-size: 35px;
  color: var(--color-black);
}
.blog__detail__schedule {
  font-family: "Montserrat-Light";
  font-size: 25px;
}
.inner {
  width: 584px;
  margin: 0 auto;
}
.blog__detail--detail {
  font-family: "Montserrat-Light";
}
.blog__detail--detail .chip__tag {
  font-family: "Montserrat-regular";
  font-size: 18px;
}
.model__title {
  font-family: "Montserrat-regular" !important;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.model__sub-title {
  font-family: "Montserrat-light" !important;
  color: var(--color-gray);
  position: relative;
  top: -0.3rem;
  font-size: 12px;
}
.items {
  flex-wrap: wrap;
}
.items .item {
  width: 16.6666666667%;
  margin-bottom: 20px;
}
.items .item.add__new {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  height: 280px;
}
@media (max-width: 1200px) {
  .items .item {
    width: 20%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(6n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 960px) {
  .items .item {
    width: 25%;
    margin-bottom: 20px;
  }
  .items .item:nth-child(5n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 768px) {
  .items .item {
    width: 33.33333%;
    margin-bottom: 20px;
  }

  .items .item:nth-child(4n) {
    padding-right: 12px !important;
  }
}
@media (max-width: 480px) {
  .items {
    justify-content: center;
  }
  .items .item {
    width: 50%;
    margin-bottom: 10px;
    padding: 10px !important;
  }
  .items .item:nth-child(3n),
  .items .item:nth-child(4n),
  .items .item:nth-child(5n),
  .items .item:nth-child(6n) {
    padding-right: 0 !important;
  }
  .items .item.add__new {
    height: 210px;
  }
}

.loading-data {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.loading-form {
  filter: blur(5px);
}
@media screen and (max-width: 1264px) {

  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 960px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 600px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}
</style>
